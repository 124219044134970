
  .button-container {
    display: flex;
    gap: 16px;
    margin: auto 20px 0;
    align-items: center;
    justify-content: center;
  }
  
  .button {
    padding: 12px 24px;
    background-color: #2563eb;
    color: white;
    border: none;
    border-radius: 8px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.2s ease;
    margin: auto;
    align-items: center;
    justify-content: center;
  }
  
  .button:hover {
    background-color: #1d4ed8;
  }