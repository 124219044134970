.navbar {
    background: linear-gradient(180deg, 
        rgba(10, 20, 10, 0.98) 0%,
        rgba(2, 3, 2, 0.677) 100%);
    padding: 0.7rem;
    position: sticky;
    top: 0;
    z-index: 1000;
    backdrop-filter: blur(10px);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
    margin: 0 auto;
    display: flex;
}

.navbar-container {
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.navbar-title {
    display: flex;
    align-items: center;
    position: relative;
}
  
  
.navbar-title:hover::after {
    background-color: #4caf4f00;
    transform: translateX(-50%);
    transition: all 0.4s ease; 
}

.title-text {
    font-weight: 600;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 2rem;
    color: #eede30;
    letter-spacing: -0.5px;
    text-shadow: 0 2px 4px rgba(0,0,0,0.2);
}

.title-number {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: 600;
    font-size: 2rem;
    color: #4caf50;
    text-shadow: 0 2px 4px rgba(0,0,0,0.2);
}

.nav-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    margin-left: 2rem;
    min-width: 0;
    overflow-y: auto;
}

.sidebar-content {
    padding: 1rem;
    height: 100%;
    display: flex;
    flex-direction: row;
}

.navbar ul {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.navbar .left {
    gap: 2rem;
}

.navbar .right {
    gap: 1.5rem;
    margin-left: 5rem;
}

.navbar a {
    color: rgba(255, 255, 255, 0.85);
    text-decoration: none;
    font-weight: 500;
    font-size: 1.1rem;
    transition: all 0.3s ease;
    padding: 0.5rem 1rem;
    border-radius: 7px;
    position: relative;
}

.navbar a:hover {
    color: #ffffff;
    background-color: rgba(76, 175, 80, 0.08);
    transition: all 0.4s ease;
}

.navbar a::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 2px;
    background-color: #4caf50;
    transition: all 0.3s ease;
    transform: translateX(-50%);
}

.navbar a:hover::after {
    width: 80%;
}

.menu-toggle {
    display: none;
    background: none;
    border: none;
    color: white;
    font-size: 1.5rem;
    cursor: pointer;
    padding: 0.5rem;
    z-index: 2;
    position: fixed; /* Fija el ícono */
    left: 40px; /* Alinea a la izquierda */
}


.profile-link {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    background: rgba(255, 255, 255, 0.046);
  }
  
  .profile-icon {
    width: 20px;
    height: 20px;
  }
  
  
  .logout-button {
    background: rgba(255, 71, 86, 0);
    border: 1px solid rgba(255, 71, 86, 0);
    color: #ff4757;
    padding: 0.5rem;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .logout-button:hover {
    background: rgba(255, 71, 87, 0.15);
    transform:perspective(1px) scale(1.2);
    box-shadow: 0 4px 12px rgba(255, 71, 87, 0.2);
  }
  
  .logout-button svg {
    width: 20px;
    height: 20px;
  }

/* Breakpoint intermedio */
@media (max-width: 1200px) {
    .navbar {
        padding: 1rem;
    }

    .navbar ul {
        gap: 1rem;
    }

    .navbar a {
        padding: 0.5rem 0.75rem;
        font-size: 1rem;
    }
}

/* Estilos responsive con sidebar */
@media (max-width:1150px) {
    .menu-toggle {
        display: block;
    }

    /* Overlay para el fondo oscuro */


    .nav-content.show::before {
        opacity: 1;
        visibility: visible;
    }

    .nav-content {
        position: fixed;
        top: 0;
        right: -100%;
        width: 300px;
        height: 100vh;
        background: rgba(6, 13, 6, 0.94);
        flex-direction: column;
        justify-content: flex-start;
        padding: 1rem;
        margin-left: 0;
        transition: all 0.3s ease;
        z-index: 1001;
        box-shadow: -4px 0 60px rgba(0, 0, 0, 0.683);
        overflow: visible;

    }

    .nav-content.show {
        right: 0;
    }

    /* Contenedor para el contenido de la barra lateral */
    .sidebar-content {
        padding: 1rem;
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow: visible;
    }

    .navbar ul {
        flex-direction: column;
        width: 100%;
        gap: 0.5rem;
        align-items: flex-start;
    }

    .navbar .left,
    .navbar .right {
        margin: 0;
        padding: 1rem 0;
        width: 100%;
    }

    .navbar .right {
        margin-top: auto; /* Empuja los elementos de la derecha hacia abajo */
        border-top: 1px solid rgba(255, 255, 255, 0.1);
        padding-top: 1rem;
    }

    .navbar a {
        font-size: 1.1rem;
        padding: 0.75rem 1rem;
        width: 100%;
        border-radius: 8px;
    }

    .navbar a::after {
        display: none;
    }

    .navbar a:hover {
        background-color: rgba(76, 175, 80, 0.15);
    }

    .profile-link {
        width: 100%;
        justify-content: flex-start;
    }

    .logout-button {
        width: 100%;
        border-radius: 8px;
        height: auto;
        padding: 0.75rem 1rem;
        justify-content: flex-start;
        background: rgba(255, 71, 87, 0.1);
        margin-top: 0.5rem;
    }

    .logout-button:hover {
        background: rgba(255, 71, 87, 0.2);
        transform: none;
    }
}

/* Ajustes para móviles pequeños */
@media (max-width: 480px) {
    .navbar {
        padding: 0.75rem;
    }

    .title-text,
    .title-number {
        font-size: 1.5rem;
    }

    .nav-content {
        width: 280px;
    }
}

/* High-density screens */
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .navbar {
        border-bottom: 0.5px solid rgba(76, 175, 80, 0.05);
    }
}