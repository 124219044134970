/* styles/Fixtures.css */

/* Fixtures Page Styles */
.fixtures-page {
    background-color: rgba(255, 255, 255, 0.8); /* Fondo semitranslúcido */
    border-radius: 12px;
    padding: 30px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15); /* Sombra más profunda */
    margin-top: 30px;
    font-family: 'Poppins', sans-serif; /* Fuente más moderna */
    backdrop-filter: blur(8px); /* Desenfoque del fondo */
    border: 1px solid rgba(255, 255, 255, 0.2); /* Borde delicado */
}

.fixtures-page h1 {
    text-align: center;
    color: #34495e; /* Azul oscuro elegante */
    font-size: 2.8rem;
    font-weight: 800;
    margin-bottom: 20px;
    letter-spacing: 2px;
    text-transform: uppercase;
    background: linear-gradient(135deg, #5fbf83, #d4e371); /* Gradiente más vibrante */
    background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shine 3s ease-in-out infinite; /* Animación de brillo en bucle */
}

@keyframes shine {
    0% { background-position: -200%; }
    100% { background-position: 200%; }
}

/* Eliminar viñetas de la lista de fixtures */
.fixtures-page ul {
    list-style: none; /* Elimina los puntos de la lista */
    padding: 0;
}

.fixture-item {
  display: flex;
  flex-direction: column; /* Organiza los elementos en columna */
  background-color: rgba(255, 255, 255, 0.9); /* Fondo translúcido */
  padding: 25px;
  border-radius: 15px;
  margin-bottom: 25px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: 100%; /* Asegura que la altura sea consistente */
}

.fixture-item:hover {
    transform: scale(1.01); /* Efecto más pronunciado al hacer hover */
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15); /* Sombra más fuerte en hover */
}


.fixture-details {
  margin-bottom: auto; /* Empuja el footer hacia abajo */
  text-align: center;
}

.team-info {
    background-color: #e9f5f0f3;
    border-radius: 15px;
    padding: 15px 18%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.team {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.team-logo {
    width: 60px;
    height: 60px;
    margin: 8px;
    transition: transform 0.3s ease;
}

.team-logo:hover {
    transform: scale(1.2); /* Efecto de agrandamiento en hover */
}

.vs {
    font-size: 1.7rem;
    font-weight: 700;
    color: #555;
    margin: 0 30px;
}

.fixture-time, .league-info, .status {
    color: #666;
    font-size: 1.1rem;
    margin: 10px 0;
}


.odds {
    margin: 15px 0;
    text-align: center;
}

.odds h2 {
    color: #666;
    font-size: 1.1rem;
    color: #3e3e3e;
}

.odd {
    background-color: #f0f4f3;
    border-radius: 10px;
    padding: 8px 15px;
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 8px;
    font-size: 1rem;
    color: #333;
}



.fixtures-list.blurred {
    filter: blur(5px);
    opacity: 0.5;
    pointer-events: none; /* Evita interacciones con la lista */
  }
  
  .bond-details-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.9); /* Fondo blanco semi-transparente */
    z-index: 10; /* Asegúrate de que esté por encima de otros elementos */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .close-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #f44336; /* Color de fondo del botón de cierre */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  
  .filters {
    display: flex;
    flex-direction: row;
    justify-content: space-between; /* Espacio entre filtros */
    padding: 1rem; /* Espaciado interno */
    background-color: #f5f5f5ca; /* Color de fondo blanco */
    border-radius: 8px; /* Bordes redondeados */
    box-shadow: 0 2px 10px rgba(165, 165, 165, 0.573); /* Sombra más sutil */
  }

  .filter-title {
    font-size: 1.1rem;
    font-weight: 600;
    color: #5e5e5e;
    margin-right: 15px;
    margin-bottom: 5px;
  }

  .filter-description {
    margin-left: 10px;
    margin-bottom: 5px;
    font-size: 1rem;
    color: #666;
    margin-right: 15px;
  }

  
  .filters label {
    font-size: 0.9rem; /* Tamaño de fuente más pequeño */
    font-weight: 500; /* Peso de fuente más ligero */
    display: flex;
    flex-direction: column;
    align-items: center; /* Centrar verticalmente */
    gap: 0.5rem; /* Espacio entre el checkbox y el texto */
  }
  
  /* Estilos del checkbox */
  .checkbox-wrapper-3 {
    align-items: center;
    margin-top: 5px;
    margin-left: 3px;
  }

  .checkbox-wrapper-3 input[type="checkbox"] {
    visibility: hidden;
    display: none;
  }
  
  .checkbox-wrapper-3 .toggle {
    position: relative;
    display: block;
    width: 40px;
    height: 20px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    transform: translate3d(0, 0, 0);
  }
  
  .checkbox-wrapper-3 .toggle:before {
    content: "";
    position: relative;
    top: 3px;
    left: 3px;
    width: 34px;
    height: 14px;
    display: block;
    background: #9A9999; /* Color de fondo del toggle */
    border-radius: 8px; /* Bordes redondeados */
    transition: 0.2s ease;
  }
  
  .checkbox-wrapper-3 .toggle span {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    display: block;
    background: white; /* Color de fondo de la esfera */
    border-radius: 10px; /* Bordes redondeados */
    box-shadow: 0 3px 8px rgba(154, 153, 153, 0.5);
    transition: all 0.2s ease;
  }
  
  .checkbox-wrapper-3 #cbx-3:checked + .toggle:before {
    background: #efe9ad; /* Color del toggle al estar marcado */
  }
  
  .checkbox-wrapper-3 #cbx-3:checked + .toggle span {
    background: #daca19; /* Color de la esfera al estar marcada */
    transform: translateX(20px);
    transition: all 0.2s cubic-bezier(0.8, 0.4, 0.3, 1.25), background 0.15s ease;
  }
  
  .checkbox-wrapper-3 #cbx-3:checked + .toggle span:before {
    transform: scale(1);
    opacity: 0;
    transition: all 0.4s ease;
  }
  

  .results-info {
    margin-right: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

  }
  
  .select {
    margin-left: 10px;
    padding: 4px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    transition: border-color 0.3s ease;
  }

  .result {
    gap: 10px;
    color: #666;
    font-size: 1.1rem;
    margin: auto;
    padding: 5px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .scores {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 700;
    color: #555;
    margin: auto;
    display: flex;
    flex-direction: row;
  }

  .score {
    background-color: #b7bf5f;
    padding: 4px 12px;
    border-radius: 5px;
    font-size: 1.2rem;
    font-weight: 600;
    color: #ffffff;
    margin: 10px 30px auto;
    margin-bottom: 10px;
  }

  .goals {
    color: #666;
    font-size: 1.1rem;
  }

  .dropdown-content {
    justify-content: left;
    max-width: 100px;
    background-color: rgba(255, 255, 255, 0.568);
    box-shadow: 0 0px 8px rgba(154, 153, 153, 0.5);
    overflow: hidden;
    scale: 0.3;
    transition: max-height 0.3s ease-out, padding 0.4s ease-out, scale 0.3s, max-width 0.3s;
    max-height: 0; /* Por defecto, el contenido está colapsado */
    padding: 0;
  }
  
  .dropdown-content.open {
    max-width: 100%;
    background-color: rgba(255, 255, 255, 0.568);
    scale: 1;
    padding: 25px;
    border-radius: 15px;
    margin: 0px 0 12px 0;
    box-shadow: 0 0px 8px rgba(154, 153, 153, 0.5);
    max-height: 700px; /* Altura máxima estimada cuando está expandido */
  }
  
  .filter-item {
    margin-bottom: 10px;
  }
  
  .items-per-page {
    font-size: medium;
    margin-bottom: 10px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
  }
  
  .pagination-button {
    background-color: #1b1b1ba6; 
    color: #fafaf9;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 10px; /* Espacio entre el icono y el texto */
    transition: background-color 0.3s;
  }
  
  .pagination-button:hover {
    background-color: #ebe069; 

  }
  
  .pagination-icon {
    font-size: 16px; /* Tamaño del icono */
  }
  
  .pagination span {
    font-size: 16px;
  }
  
  .filter-dropdown-button {
    margin: 10px 0;
    background-color: #6c757d9c; /* Color más moderno y neutral */
    color: #ffffff;
    border: none;
    padding: 8px 15px;
    border-radius: 10px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    transition: background-color 0.3s, box-shadow 0.3s;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Sombra suave */
  }

  
  .filter-dropdown-button:hover {
    background-color: #5a6268; /* Efecto hover más oscuro */
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.15); /* Sombra más pronunciada en hover */
  }
  
  .filter-dropdown-button:active {
    background-color: #13599f; /* Color más oscuro al hacer clic */
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Sombra más suave al hacer clic */
  }


  /* Grid layout for fixtures */
.fixtures-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Ajustable */
  gap: 20px;
  margin: 20px 0;
}

/* Tarjetas individuales */
.fixture-card {
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.9), rgba(245, 245, 245, 0.7));
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.fixture-card:hover {
  transform: scale(1.03);
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2);
}

/* Encabezado de la tarjeta */
.fixture-card-header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 15px;
  background: linear-gradient(135deg, #f0f0f0, #e6e6e6);
}

.fixture-card-header-finished {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 15px;
  background: linear-gradient(135deg, #f0f0f0, #e6e6e6);
}


.team-logo {
  width: 50px;
  height: 50px;
  transition: transform 0.3s ease;
}

.team-logo:hover {
  transform:perspective(100px) scale(1.05);
}

/* Cuerpo de la tarjeta */
.fixture-card-body {
  padding: 15px;
  text-align: center;
  flex: 1;
}

.fixture-title {
  font-size: 1.3rem;
  font-weight: 700;
  color: #34495e;
  margin-bottom: 10px;
}

.fixture-info {
  font-size: 1rem;
  color: #555;
}

/* Pie de la tarjeta */
.fixture-card-footer {
  padding: 10px;
  text-align: center;
  background: rgba(0, 128, 0, 0.1);
}

.fixture-card-footer-finished {
  padding: 10px;
  text-align: center;
  background: rgba(119, 128, 0, 0.1);

}


.bet-button {
  background-color: #5fbf83;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 1rem;
  cursor: pointer;
  margin: 5px;
  transition: background-color 0.3s linear;
}

.bet-button:hover {
  background-color: #4caf70;

}
