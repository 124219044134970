/* General Page Styling */
.bet-history-page {
  max-width: 900px;
  margin: 0 auto;
  padding: 30px;
  background-color: rgba(255, 255, 255, 0.8); /* Fondo semitranslúcido */
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  font-family: 'Helvetica Neue', sans-serif;
  color: #333;
  justify-content: center;
  animation: containerOpen 0.4s ease-out forwards;
  

}

@keyframes containerOpen {
  0% {
      opacity: 0;
      filter: blur(25px);
  }
  100% {
      opacity: 1;
      transform: translateY(0) scale(1);
      filter: blur(0);
  }
}

.bet-history-page h1 {
  text-align: center;
  color: #2c3e50;
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 25px;
  border-bottom: 2px solid #3d413b;
  padding-bottom: 10px;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

/* Bet Item Styling */
.bets {
  gap: 10px;
  display: flex;
  justify-content: space-between;

}


.bet-atribute {
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
}

/* Bet Details Styling */
.bet-details {
  display: flex;
  flex-direction: column;
  gap: 12px;
  color: #34495e;
}

.bet-league, .bet-round, .bet-date, .bet-result, .bet-status {
  font-size: 16px;
}

.bet-league {
  font-weight: 600;
}

.bet-date {
  color: #7f8c8d;
  font-style: italic;
}

/* Status Colors */
.bet-status {
  font-size: 18px;
  padding: 8px;
  border-radius: 8px;
  text-align: center;
  font-weight: bold;
}

.bet-status.won {
  background-color: #2ecc71;
  color: white;
}

.bet-status.lost {
  background-color: #e74c3c;
  color: white;
}

/* Loading and Error States */
.loading, .error {
  text-align: center;
  font-size: 20px;
  margin-top: 50px;
  color: #95a5a6;
}

.error {
  color: #e74c3c;
}
