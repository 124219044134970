.bond-details {

    max-width: 100%; /* Controla el ancho máximo del detalle */
    width: 70%; /* Permite que sea responsivo */
    text-align: center; /* Centra el texto */
    margin: auto;
  }
  
  .cancel-button {
    background-color: #e45d5d;
    color: white;
    border: none;
    padding: 12px 25px;
    border-radius: 30px;
    cursor: pointer;
    font-size: 1.1rem;
    transition: background-color 0.3s ease, transform 0.2s ease;
    margin-top: 15px;
}

.cancel-button:hover {
    background-color: #b02d2d;
    transform: translateY(-3px); /* Efecto de movimiento hacia arriba */
}

.odd {
  padding: 5px 10px; /* Adjusted padding for buttons */
  border: 2px solid transparent;
  background-color: #eaeaea;
  color: #333;
  border-radius: 10px;
  cursor: pointer;
  font-size: 1rem;
  transition: border-color 0.3s ease, background-color 0.3s ease;
}

.country-info {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  gap: 0px;
}

.country-flag {
  width: 30px;
  height: auto;
  margin-left: 5px;
}

.country-name {
  margin-left: 5px;
  font-size: 1.1rem;
}
