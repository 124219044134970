.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 1200px; /* Limita el ancho máximo */
  margin: 0 auto; /* Centra la página */
}

.widgets-container {
  display: flex;
  flex-direction: row;
  margin: auto;
  justify-content: flex-start; /* Alinea widgets al inicio */
  align-items: flex-start; /* Alinea widgets hacia arriba */
  gap: 20px; /* Espacio entre widgets */
  width: 100%; /* Toma el ancho completo */
}

.fixtures-widget {
  min-width: 300px; /* Ancho mínimo para los widgets */
  max-width: 900px; /* Ancho máximo para los widgets, ajusta según lo necesites */
  border-radius: 8px; /* Bordes redondeados */
  margin-top: -30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative; /* Permite que el botón se posicione absolutamente */
}

.welcome-auth-widget {
  display: flex;
  flex-direction: column; /* Apila el widget de bienvenida y el de autenticación */
}

.welcome-widget {
  min-width: 300px;
  max-width: 900px;
  background: linear-gradient(135deg, #f5f08dcf, #135a0bd6); /* Fondo degradado */
  border-radius: 15px;
  padding: 30px 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15); /* Sombra más pronunciada */
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 10px; /* Más espacio debajo */
  color: white; /* Texto en blanco */
  transition: transform 0.3s ease; /* Animación suave al hacer hover */
}

.welcome-widget h1 {
  font-size: 1.7rem; /* Tamaño de fuente más grande */
  margin-bottom: 5px; /* Espacio adicional debajo del título */
}

.welcome-widget p {
  font-size: 1rem; /* Tamaño de fuente más grande */
  margin-bottom: 20px; /* Espacio adicional debajo del párrafo */
}

.dividor {
  width: 100%;
  height: 1px;
  background-color: white; /* Línea divisoria blanca */
  margin: 0px 0; /* Espacio adicional arriba y abajo */
}

.auth-widget {
  display: flex;
  background-color: rgba(255, 255, 255, 0.8); /* Fondo claro */
  border-radius: 15px; /* Bordes redondeados */
  flex-direction: column; /* Alinea elementos en columna */
  align-items: center; /* Centra contenido */
}


.button-more {
  text-decoration: none;
  background: linear-gradient(135deg, #5fbf83, #d4e371); /* Degradado del botón */
  color: white; /* Color del texto del botón */
  padding: 10px 20px; /* Espaciado interno del botón */
  border-radius: 25px; /* Bordes redondeados */
  position: absolute; /* Posiciona el botón absolutamente */
  bottom: 20px; /* Ajusta la distancia desde la parte inferior del contenedor */
  left: 50%; /* Centra el botón horizontalmente */
  transform: translateX(-50%); /* Ajusta para centrar el botón */
  z-index: 1; /* Asegura que el botón esté encima de otros elementos */
}
