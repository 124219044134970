/* General Page Styling */
.recommendations-page {
    max-width: 900px;
    margin: 0 auto;
    padding: 30px;
    background-color: #f4f4f9;
    border-radius: 15px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    color: #333;
    justify-content: center;
  }
  
  .recommendations-page h2 {
    text-align: center;
    color: #2c3e50;
    font-weight: bold;
    margin-bottom: 25px;
    border-bottom: 2px solid #3d413b;
    padding-bottom: 10px;
  }
  
  /* Button Styling */
  .recommendations-page button {
    display: block;
    width: 200px;
    margin: 20px auto;
    padding: 10px;
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .recommendations-page button:hover {
    background-color: #2980b9;
  }
  
  .recommendations-page button:disabled {
    background-color: #95a5a6;
  }
  
  /* Recommendations List Styling */
  .recommendations-list {
    margin-top: 20px;
  }
  
  .recommendations-list ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  /* Fixture Item Styling */
  .fixture-details {
    flex-direction: column;
    border-radius: 10px;
    background-color: #ffffff;
    margin-bottom: 15px;
  }
  
  
  /* Fixture Details Styling */

  
  .recommended-winner {
    font-weight: bold;
  }
  
  /* Loading and Error States */
  .loading, .error {
    text-align: center;
    font-size: 20px;
    margin-top: 50px;
    color: #95a5a6;
  }
  
  .error {
    color: #e74c3c;
  }
  