/* AddFunds.css */
.add-funds-container {
  padding: 15px;
  border-radius: 12px;
  max-width: 500px;
  width: 300px;
  margin: auto;
  text-align: center;
}

.add-funds-container h3 {
  margin-top: 0px;
  font-size: 2rem;
  margin-bottom: 20px;
  color: #333;
  text-transform: uppercase;
}

.error-message {
  color: red;
  font-size: 0.9rem;
  margin-bottom: 10px;
}

.funds-input {
  width: 100%;
  padding: 15px;
  margin-bottom: 20px;
  border: 2px solid #ccc;
  border-radius: 10px;
  font-size: 1.1rem;
  transition: border-color 0.3s ease;
}

.quick-select {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.quick-select button {
  padding: 10px;
  border: 2px solid transparent;
  background-color: #f0f4f3;
  border-radius: 8px;
  cursor: pointer;
  font-size: 0.8rem;
  transition: border-color 0.3s ease, background-color 0.3s ease;
}

.quick-select button:hover {
  background-color: #bfcddc;
  border-color: #5f7690;
}


.payment-method {
  margin-bottom: 20px;
}



.payment-method label {
  font-size: 1rem;
  margin-right: 10px;
  font-weight: 600;
  margin: 5px;
  cursor:default;
  color: #2d2d2df3;
}


.payment-select {
  margin-top: 5px;
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  border: 2px solid #ccc;
  font-size: 1rem;
  cursor: pointer;
}

